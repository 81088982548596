import React, {Fragment, useEffect, useState} from 'react';

import s from './ReportLeadgidConversionPage.module.scss'
import {ITableDataHeader} from "../../../components/UI/Table/Table";
import Table from "../../../components/UI/Table";
import TableRow from "../../../components/UI/Table/components/TableRow";
import TableColumn from "../../../components/UI/Table/components/TableColumn/TableColumn";
import {ENDPOINTS} from "../../../api/endpoints";
import StatusBadge from "../../../components/StatusBadge";
import TablePagination from "../../../components/UI/Table/components/TablePagination";
import Loader from "../../../components/UI/Loader";
import {DataXPartnersStats} from "../../../api/interface/XPartners";
import {useParams} from "react-router-dom";
import {DateTime} from "luxon";


export default function ReportLeadgidConversionPage() {
    const tableGrid = ['200px', '140px', '140px', '1fr', '200px', '140px'];
    const headerData: ITableDataHeader[] = [
        {title: 'Дата'},
        {title: 'Статус'},
        {title: 'ID'},
        {title: 'Название'},
        {title: 'Цель'},
        {title: 'Валюта', position: 'flex-end'}
    ];

    const DATA_PAGE_LIMIT: number = 20
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [rawData, setRawData] = React.useState<DataXPartnersStats>({
        data: [],
        pagination: {}
    });
    const {id, leadId} = useParams()
    const [dateFrom, setDateFrom] = useState<string>(DateTime.now().toISODate())
    const [dateTo, setDateTo] = useState<string>(DateTime.now().toISODate())

    function getData(page: number, date_from: string, date_to: string) {
        setRawData({data: [], pagination: {}})
        setIsLoading(true)

        ENDPOINTS.REPORTS.STATS2.CONVERSATION.GET({
            id: id || leadId,
            page: page,
            date_to: date_to,
            date_from: date_from,
            limit: DATA_PAGE_LIMIT
        }).then((res) => {
            setRawData(res);
            setIsLoading(false)
        })
    }

    useEffect(() => {
        getData(currentPage, dateFrom, dateTo)
    }, [currentPage, dateFrom, dateTo]);


    return (
        <div className={s.container}>
            <div className={s.container__products}>
                <div className={s.container__filter}>
                    <h3>Фильтровать: </h3>
                    <div>
                        <label htmlFor="date_from">
                            <span>От</span>
                            <input id="date_from" type="date" defaultValue={DateTime.now().toISODate()}
                                   onChange={(e) => setDateFrom(e.target.value)}/>
                        </label>

                        <label htmlFor="date_to">
                            <span>До</span>
                            <input id="date_to" type="date" defaultValue={DateTime.now().toISODate()}
                                   onChange={(e) => setDateTo(e.target.value)}/>
                        </label>
                    </div>
                </div>

                <Table dataHeaders={headerData} gridWidthSize={tableGrid}>
                    {headerData && <div className={s.underlined}/>}
                    {rawData?.data.map((item, index) => (
                        <Fragment key={index}>
                            <TableRow gridWidthSize={tableGrid} hover={true}>
                                <TableColumn>
                                    <div>{DateTime.fromFormat(item.conversion_date, 'yyyy-LL-dd HH:mm:ss').toFormat('D TT')}</div>
                                </TableColumn>
                                <TableColumn>
                                    <div><StatusBadge status={item.status}/></div>
                                </TableColumn>
                                <TableColumn>
                                    <div>{item.conversion_id}</div>
                                </TableColumn>
                                <TableColumn>
                                    <div>{item.offer_name}</div>
                                </TableColumn>
                                <TableColumn>
                                    <div>{item.goal_name}</div>
                                </TableColumn>
                                <TableColumn position={'flex-end'}>
                                    <div>{item.currency}</div>
                                </TableColumn>
                            </TableRow>
                        </Fragment>
                    ))}
                </Table>

                {isLoading && <Loader/>}

                <TablePagination currentPage={currentPage}
                                 totalPages={Math.round(Number((rawData?.pagination.count || 0) / DATA_PAGE_LIMIT))}
                                 onPageChange={(page) => {
                                     setCurrentPage(page)
                                 }}/>
            </div>
        </div>
    )
}
